import React, { Component } from 'react';

class Terms extends Component {

    render() {

        const { t } = this.props;

        return (
            <>
                <div className="container mb-5 mt-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="terms">
                                <h1>{t('ALGEMENE VERKOOPS- EN AANNEMINGSVOORWAARDEN DO IT FOR ME')}</h1>
                                <h2>ARTIKEL 1 : TOEPASSING</h2>
                                Elke bestelling en/of verkoop houdt voor de klant door diens ondertekening van de offerte van rechtswege de aanvaarding in
                                van deze voorwaarden, met uitsluiting van enig ander document of andere voorwaarden. Er kunnen geen verkoops- of
                                aannemingsvoorwaarden voorrang hebben op deze voorwaarden, tenzij dit door DO IT FOR ME, hierna “de prestatieplichtige”
                                genoemd, uitdrukkelijk schriftelijk wordt aanvaard.
                                <h2>ARTIKEL 2 : OFFERTES</h2>
                                Onze offertes, die tot de ondertekening door de klant door de prestatieplichtige louter als inlichting en met voorbehoud voor
                                eventuele materiële vergissingen verstrekt worden en tot die ondertekening geenszins een bindend aanbod door de
                                prestatieplichtige inhouden, zijn geldig gedurende 30 dagen.
                                <h2>ARTIKEL 3 : PUBLICITEIT</h2>
                                Foto’s, catalogi, folders of andere door prestatieplichtige geleverde publiciteitsdocumenten hebben geen enkele contractuele
                                waarde. Dit materiaal houdt geen enkele beschrijving van de te leveren prestaties in, maar is enkel een illustratie van mogelijke
                                door de prestatieplichtige te leveren prestaties. De werkelijk door de prestatieplichtige uit te voeren prestaties (vorm, karakter
                                op maat, afwerking en individualisering) blijken uit de bestelling conform de wensen van de klant. Kleurvariaties blijven
                                onderworpen aan de algemeen toegelaten toleranties op dat gebied. Stalen hebben enkel een indicatieve waarde.
                                <h2>ARTIKEL 4 : BESTELLING</h2>
                                De klant doet zijn bestelling door de schriftelijke ondertekening van de offerte (al dan niet met bijgevoegde plannen). Vanaf die
                                ondertekening is de bestelling bindend voor de beide partijen onder voorbehoud van de mededeling door de klant van alle voor
                                de uitvoering van de door de prestatieplichtige te leveren prestaties noodzakelijke inlichtingen en van een desgevallend
                                noodzakelijke technische controle ter plaatse door de prestatieplichtige om te zien of de door de klant doorgegeven
                                plaatsomstandigheden kloppen en de door de klant gevraagde uitvoering de facto mogelijk is. Prijzen van een offerte zijn slechts
                                geldig voor de hele bestelling en prijzen gehanteerd bij eerdere bestellingen zijn niet bindend voor latere bestellingen. Na
                                ondertekening van de offerte door de klant worden geen wijzigingen aan of annulering van de bestelling meer aanvaard. Bij
                                annulering door de klant van een ondertekende bestelling is de prestatieplichtige gerechtigd om een conventionele
                                schadevergoeding te vorderen die gelijk is aan 10% van het ondertekende offertebedrag (met een minimum van 125,00 euro),
                                vermeerderd met de door de prestatieplichtige reeds gedane kosten (administratie-, materiaal-, fabricage en andere kosten).
                                De door de prestatieplichtige te leveren prestaties (raadgevingen, levering en/of installatie van de bestelde goederen en
                                toebehoren) omvatten enkel en alleen wat vermeld staat in de bestelbon. Al wat niet vermeld staat in de bestelbon is niet in
                                de bestelling inbegrepen en zal desgevallend voorwerp uitmaken van een facturatie aan een specifieke meerprijs daarvoor en
                                met toepassing van deze voorwaarden. Alle door de prestatieplichtige verschafte raadgevingen omtrent levering en/of
                                installatie van het door de klant bestelde (informed consent) worden ten laatste bij de bestelling (of in geval van de voornoemde
                                technische controle uiterlijk dan) als door de klant aanvaard beschouwd. Nadien kunnen er daarover geen betwistingen meer
                                geformuleerd worden.
                                <h2>ARTIKEL 5 : PRIJZEN</h2>
                                Alle prijzen zijn uitgedrukt in euro’s. De in foto’s, catalogi, folders of andere publiciteitsdocumenten vermelde prijzen
                                hebben enkel een indicatieve waarde. De prestatieplichtige is enkel gebonden door de in de bestelling van de klant
                                (ondertekende offerte met eventueel bijgevoegde plannen) vermelde prijzen. Bijkomende en niet in de offerte of bestelling
                                vermelde voorzieningen opgelegd door een veiligheidscoördinator worden supplementair doorgerekend aan de klant. Tenzij
                                anders vermeld, zijn de prijzen inclusief BTW (die al naargelang de verklaring van de klant
                                Bij niet-betaling van de facturen uiterlijk op de vervaldatum wordt het openstaande bedrag van rechtswege en zonder
                                ingebrekestelling verhoogd met een conventionele intrest van 10% per jaar vanaf de vervaldag en met een forfaitaire
                                schadevergoeding van 10% van het openstaande bedrag.
                                Het versturen van een betalingsherinnering kost de klant telkens automatisch 12 euro.
                                Bij laattijdige betaling door de klant van een aan de prestatieplichtige verschuldigd (al dan niet partieel) bedrag kan de
                                prestatieplichtige verdere leveringen en prestaties opschorten zonder voorafgaande verwittiging en zonder dat er nadelige
                                gevolgen ten laste kunnen gelegd worden van de prestatieplichtige.
                                <h2>ARTIKEL 6 : FACTURATIE</h2>
                                In geval van installatie door de prestatieplichtige worden de bestelde goederen als volgt aan de klant gefactureerd: factuur 1: 
                                30% van de goederen aan 21% BTW tarief, na betaling factuur 1 volgt de opmeting, waarna factuur 2 : 70% van de 
                                goederen aan 21% BTW tarief, na betaling factuur 2 worden de te installeren materialen besteld en zodra de prestatieplichtige 
                                die geleverd krijgt zullen deze geïnstalleerd worden bij de eigenaar. Na installatie volgt een slotfactuur 3 van het saldo 
                                van de overeengekomen installatiekost aan 21% indien nieuwbouw of jonger dan 10 jaar of aan 6% met verrekening van 
                                eerste 2 facturen indien ouder dan 10 jaar + de eventuele te factureren extra’s. Indien klant afziet van bestelling na factuur 1, 
                                dus na opmeting, zal de klant het betaalde voorschot terugbetaald krijgen, met uitzondering van €175 (incl. BTW) 
                                welke wordt ingehouden, als vergoeding voor de prestaties in het kader van de opmeting. 
                                In de facturen wordt standaard een kredietbeperkingspercentage voorzien van 2%. 
                                Bij niet-betaling van de facturen uiterlijk op de vervaldatum door B2B-klanten wordt het openstaande bedrag van rechtswege 
                                en zonder ingebrekestelling verhoogd met een conventionele intrest van 10% per jaar vanaf de vervaldag 
                                en met een forfaitaire schadevergoeding van 10% van het openstaande bedrag. Het versturen van een betalingsherinnering kost 
                                de klant telkens automatisch 12 euro. Bij laattijdige betaling door de klant van een aan de prestatieplichtige 
                                verschuldigd (al dan niet partieel) bedrag kan de prestatieplichtige verdere leveringen en prestaties opschorten zonder voorafgaande 
                                verwittiging en zonder dat er nadelige gevolgen ten laste kunnen gelegd worden van de prestatieplichtige.   
                                Voor B2C-klanten wordt na het eerste versturen van een (gratis) betalingsherinnering de forfaitaire schadevergoeding beperkt tot 
                                de maxima van art. XIX.4 van het Wetboek Economisch Recht, welke op het moment van de huidige versie van deze voorwaarde 
                                als volgt zijn bepaalt: • 20 EUR (voor factuurbedragen ≤ 150 EUR) • 30 EUR + 10% van het factuurbedrag tussen 150 EUR en 500 EUR 
                                (voor facturen > 150 EUR maar ≤ 500 EUR) • 65 EUR + 5% van het factuurbedrag boven de 500 EUR (voor facturen > 500 EUR) 
                                • Met een absoluut maximum van 2000 EUR. 
                                De door B2C-klanten verschuldigde conventionele nalatigheidsintrest betreft de intrestvoet bepaald in artikel 5 van de Wet 
                                van 02/08/2002 ter Bestrijding van de Betalingsachterstand bij Handelstransacties vanaf de 22e kalenderdag na de herinnering.
                                <h2>ARTIKEL 7 : LEVERING EN/OF INSTALLATIE</h2>
                                <h3>7.1 : VERPLICHTINGEN VAN DE KLANT</h3>
                                De klant verbindt er zich toe om enerzijds op de plaats van levering alle voorwerpen te verwijderen die de prestatieplichtige
                                zouden kunnen hinderen en anderzijds de bescherming op zich te nemen van de vloer- en muurbekleding van de ruimten waar
                                de goederen dienen geplaatst te worden en van het overige meubilair dat hij ter plaatse laat tijdens de levering en plaatsing. De
                                klant zal kosteloos elektriciteit en water ter beschikking stellen evenals de toegang verzekeren voor een vlotte uitvoering van
                                de levering en/of installatie. Indien noodzakelijk zal de klant de noodzakelijke parkeerruimte (plaats van 20 meter lang
                                voor het gebouw waar de goederen dienen geleverd te worden) reserveren. Alle kosten, verbonden aan de reservatie van
                                parkeerplaatsen, liften, e.d.. zijn ten laste van de klant. De klant zal zelf instaan voor de noodzakelijke vergunningen bij lokale
                                overheden. Op de plaats, waar de nieuwe bestelling moet staan, worden alle bestaande materialen of voorwerpen door de klant
                                zelf afgebroken en weggevoerd en dit ten laatste bij de aanvang van de geplande levering of installatie. De klant voorziet een
                                werkruimte van minimaal 8 m² voor de plaatser (de installatieplaats niet inbegrepen). De klant zorgt zelf voor de bescherming
                                tegen stof van de in de werkruimte of de ruimte verblijvende meubels of elementen. Indien de hiervoor omschreven
                                verplichtingen door de klant niet worden nageleefd, heeft de prestatieplichtige het recht om de levering en/of installatie te
                                annuleren. Het geheel van kosten ten gevolge van die annulatie van levering en plaatsing, zijn ten laste van de klant conform
                                het voornoemde artikel 4.
                                <h3>7.2 : TIJDSTIP VAN LEVERING EN/OF INSTALLATIE</h3>
                                Het vervoer, de levering en/of installatie zullen worden uitgevoerd tijdens “normale werkdagen en werkuren” (indicatief
                                daarvoor zijn het Arbeidsreglement van de prestatieplichtige of de betreffende contractuele voorwaarden tussen de
                                prestatieplichtige en diens onderaannemer) De kosten voor werken of leveringen buiten deze periode en op verzoek van de
                                klant zullen hem supplementair in rekening gebracht worden. De leverings- en installatietermijnen worden steeds louter
                                indicatief vermeld. De overschrijding van de als richttermijn vermelde leverings- of plaatsingstermijn kan niet ingeroepen
                                worden om de ontbinding van de overeenkomst te vragen of een schadevergoeding te vorderen.
                                <h3>7.3 : OVERMACHT</h3>
                                Alle gevallen van overmacht ontlasten de prestatieplichtige van elke verantwoordelijkheid. Dit is zonder limitatief te zijn onder
                                meer het geval voor overheidsdaden, personeelsgebrek, stakingen, oorlog, lock-out, oproer, uitzonderlijke
                                weersomstandigheden, brand, machinebreuk, laattijdige ontvangst van al of niet beschadigde onderdelen, ongevallen en
                                beschadigingen tijdens het vervoer.
                                <h3>7.4 : INSTALLATIE</h3>
                                Elke installatie wordt uitsluitend door de gespecialiseerde diensten van de prestatieplichtige uitgevoerd. Voor niet in de
                                bestelling vermelde bijkomende werken (zoals zonder ook hier limitatief te willen zijn elektriciteit, betegeling, schilderwerk,
                                tuinaanleg of beplanting), waarvoor door gespecialiseerde aannemers aparte prijsopgaven dienen opgemaakt te worden, is de
                                prestatieplichtige niet verantwoordelijk. In dat geval blijven die gespecialiseerde aannemers exclusief verantwoordelijk voor
                                de uitvoering van hun werken en aansprakelijk voor de eventuele schade die zij zouden veroorzaken. Indien de ruimten, die
                                aan de prestatieplichtige voor de overeengekomen installatie ter beschikking worden gesteld niet overeenstemmen met de
                                beschrijving op de bestelbon en/of de daarbij gevoegde plannen, zal de prestatieplichtige, op eigen initiatief, ofwel het concept
                                van indeling van het te installeren product kunnen veranderen of aanpassen, ofwel een eventuele toeslag aanrekenen op de in
                                de bestelling voorziene installatiekost, zonder dat de klant zijn bestelling kan annuleren.
                                <h2>ARTIKEL 8 : OPLEVERING EN GARANTIE</h2>
                                Na de levering en/of installatie dient de klant de door de prestatieplichtige verrichte prestaties na te zien.
                                Incorporatie (zoals het laten aanwerken of bepleisteren) van beschadigde of gebrekkige goederen impliceert de aanvaarding
                                van de goederen in de staat, waarin zij zich bevinden.
                                Indien de klant als consument de bescherming geniet van de wet van 01/09/2004 betreffende de bescherming van
                                consumenten bij verkoop van consumptiegoederen dient hij de prestatieplichtige binnen de twee maanden vanaf de dag,
                                waarop hij het gebrek heeft vastgesteld, met een aangetekend schrijven op de hoogte te brengen van het gebrek aan
                                overeenstemming van het consumptiegoed, en dit op straffe van verval. Hij zal daarbij ook een kopie van de bestelbon, van
                                de betreffende factuur en van alle andere noodzakelijke dossierinformatie overmaken aan de prestatieplichtige. De door de
                                voornoemde wet voorziene ontbinding van de overeenkomst indien in geval van niet-conformiteit de herstelling of vervanging
                                onmogelijk of buiten verhouding is, is uitgesloten in geval van een niet-conformiteit van geringe waarde.
                                In geval van garantie voor verborgen gebreken dient de klant de prestatieplichtige op de hoogte te brengen per aangetekend
                                schrijven binnen de 15 dagen na de vaststelling van het verborgen gebrek.
                                Zonder ook hier limitatief te zijn is er geen garantie in geval van overmacht, onregelmatig of slecht onderhoud of slechte
                                bewaring door de klant, normaal gebruik of normale slijtage (bijvoorbeeld : algemene verwering zoals verkleuring, verbleking of
                                vervorming), tussenkomst en manipulaties aan de goederen door derden (zij die niet voor de prestatieplichtige of voor één van
                                haar onderaannemers werken), het (her)installeren van goederen, contact met vocht of grote temperatuursveranderingen,
                                overbelasting van planken of schuiven, directe blootstelling aan het zonlicht of buitensporige belasting van de goederen, zoals
                                scharnieren hendels of geleiders).
                                Indien de klant de bescherming van de wet van 01/09/2004 niet geniet, erkent de klant door zijn nazicht onmiddellijk na de
                                levering en/of installatie dat de door de prestatieplichtige verrichte levering en/of installatie beantwoordt aan zijn bestelling en
                                vrij is van zichtbare gebreken. Verborgen gebreken moeten in dat geval eveneens binnen de 15 dagen na de vaststelling ervan
                                per aangetekend schrijven aan de prestatieplichtige gemeld worden.
                                Er is enkel sprake van garantie door de prestatieplichtige na het effectief ontvangen van alle factuurbedragen.
                                De aansprakelijkheid van de prestatieplichtige beperkt zich tot de directe schade. Onrechtstreekse schade komt niet voor
                                vergoeding in aanmerking.
                                <h2>ARTIKEL 9 : EIGENDOMSVOORBEHOUD</h2>
                                Alle bij de prestatieplichtige bestelde artikelen, goederen of inrichtingselementen worden verkocht onder voorbehoud van
                                de eigendomsrechten van de prestatieplichtige die slechts na volledige betaling van alle hem verschuldigde bedragen op
                                de klant overgaan. Niet-betaling, zelfs gedeeltelijk, geeft de prestatieplichtige het recht bovenvermelde goederen bij de klant
                                of zijn rechthebbenden terug te halen. Dit recht zal zelfs uitgevoerd worden ingeval van samenloop van schuldeisers. De
                                overgang van risico gebeurt op het moment van de levering en/of installatie door de prestatieplichtige.
                                <h2>ARTIKEL 10 : PERSOONSGEGEVENS VAN DE KLANT</h2>
                                De klant geeft door zijn bestelling toestemming voor de verwerking van zijn persoonsgegevens voor één of meer specifieke
                                doeleinden van de prestatieplichtige.
                                De persoonsgegevens van de klant worden door de prestatieplichtige verwerkt conform de algemene verordening
                                gegevensbescherming (AVG) (of in het Engels: general data protection regulation (GDPR) en gepubliceerd op 04/05/2016) en
                                zijn enkel bestemd voor intern gebruik door de prestatieplichtige en door de ondernemingen die contractueel verbonden zijn
                                met deze laatste.
                                Die persoonsgegevens kunnen door de prestatieplichtige gebruikt worden voor verkoopsdoeleinden, zoals naverkoopdienst,
                                publiciteit, brochures, gebruik op sociale media van de prestatieplichtige, waarborg, veiligheid alsook klantenbeheer voor de
                                producten verdeeld door bovenvermelde gebruikers.
                                Conform de voornoemde AVG heeft de klant met betrekking tot zijn persoonsgegevens een recht op inzage, een recht op
                                rectificatie, een recht om vergeten te worden, een recht op beperking van de verwerking van zijn persoonsgegevens, een
                                recht op overdraagbaarheid van zijn gegevens en een recht van bezwaar.
                                <h2>ARTIKEL 11 : BETWISTINGEN EN TOEPASSELIJKE WETGEVING</h2>
                                Zowel de voornoemde bestellingen en facturen als deze algemene voorwaarden zijn uitsluitend onderworpen aan het Belgisch
                                recht.
                                De nietigheid van één van de bepalingen van deze voorwaarden (artikel of een gedeelte van een artikel) heeft geen invloed op
                                de geldigheid van de overige bepalingen van deze voorwaarden.
                                In geval van betwisting of geschil waarbij de prestatieplichtige verweerder is of waarbij de klant geen consument is, zijn enkel
                                de rechtbanken van het arrondissement Leuven bevoegd.
                                In geval van een betwisting of geschil waarbij de klant een consument is én de prestatieplichtige eiser is, is naar keuze van de
                                prestatieplichtige de rechter bevoegd van de woonplaats van de verweerder of van de plaats, waar de overeenkomst
                                ondertekend werd of van de plaats van de betreffende levering of installatie.
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Terms;
